import React from 'react';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Signup from '../sections/signup';

export default function Subscribe(props) {
  return (
    <Layout 
      showHeader={false} 
      showMenu={false}
      showFooter={false}
    >
      <SEO
        title="MOU - Modelagem Orientada ao Usuário"
        description="MOU - Modelagem Orientada ao Usuário"
      />
      <Signup promocode={props.promocode} accountType={props.accountType} />
    </Layout>
  );
}
